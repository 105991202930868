<template>
  <label class="flex gap-1">
    <FormCheckbox
      ref="checkbox"
      :required="props.required"
      v-model="localPermissionGiven"
      @update:checked="updatePermission"
    />
    <i18n-t
      :keypath="
        props.isNewsletter
          ? 'newsletterConsent.text'
          : props.pluralText
          ? 'marketingPermission.pluralText'
          : 'marketingPermission.text'
      "
      tag="div"
      scope="global"
      class="body-xs"
    >
      <template #siteName>
        <span>{{ config.public.site.name }}</span>
      </template>
      <template #alike>
        <CommonTooltip :text="$t('newsletterConsent.alike.more')">
          <span class="underline">{{
            $t('newsletterConsent.alike.text')
          }}</span>
        </CommonTooltip>
      </template>
      <template #group>
        <CommonTooltip :text="$t('newsletterConsent.group.more')">
          <span class="underline">{{
            $t('newsletterConsent.group.text')
          }}</span>
        </CommonTooltip>
      </template>
      <template #privacyPolicy>
        <NuxtLink
          :to="{
            name: 'article',
            params: {
              id: config.public.site.cookiePolicy,
            },
          }"
          class="underline hover:text-blue"
        >
          <span>{{ $t('newsletterConsent.privacyPolicy.text') }}</span>
        </NuxtLink>
      </template>
    </i18n-t>
  </label>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

const props = withDefaults(
  defineProps<{
    permissionGiven: boolean
    pluralText?: boolean
    required?: boolean
    isNewsletter?: boolean
  }>(),
  {
    permissionGiven: false,
    pluralText: false,
    required: false,
    isNewsletter: false,
  }
)

const localPermissionGiven = ref(props.permissionGiven)

const emit = defineEmits(['update:permission'])

const emitPermission = () => {
  emit('update:permission', localPermissionGiven.value)
}

const updatePermission = (newValue: boolean) => {
  localPermissionGiven.value = newValue
  emitPermission()
}
</script>
